<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero">
					<figure>Diversity & inclusion</figure>
					<h1>Attracting & Advancing <br>Diverse Talent </h1>
					<p>As the external landscape grows increasingly complex firms demand regional approaches to global challenges, recruiting diverse talent is more important than ever for DTCC to remain at the forefront of innovation.</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<div class="body-columns">
						<div class="body-columns__content">

							<p>Increasing a job seeker’s awareness of our business, workplace culture and career opportunities at DTCC require a team effort from everyone across our organization.  </p>

							<p>One of the main drivers of our continued success is the diversity of our employees. Despite the tumultuous year, our focus on diversity remained a primary goal as we continually looked for innovative ways to recruit the best and most talented group of professionals. Key to this was our agility in shifting from in-person to virtual recruiting and events, which opened new opportunities for us to identify and engage with a much wider pool of diverse talent.  </p>

							<p>We partnered with organizations like iRelaunch, National Black MBA, Lesbians Who Tech, ALPFA , Jopwell, Fairygodboss, as well as select Historically Black Colleges and Universities (HBCUs) and Hispanic-Serving Institutions (HSIs), as part of our refocused strategy.</p>
						</div>

						<div class="body-columns__images">
							<img src="~@/assets/csr/talentImg1@2x.jpg" width="340" />
						</div>
					</div>
				</div>

				<div class="body-quote body-quote--1">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--left">
						<div class="body-quote__quote">
							“We intentionally focus on building a diverse workforce, reflecting the communities that we serve. We are committed to attracting and advancing diverse talent and we believe this will make us an even stronger and more competitive company.”
							<div class="body-quote__credit">— <strong>Jodi Brockington</strong>,  Director, Diverse Talent Management & Advancement  </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<div class="body-columns--small">
						<h2>Nurturing <span>Tomorrow’s Workforce</span></h2>
						<p>Building the workforce of the future doesn’t end with hiring and retaining talent. The most effective human capital strategy is one that creates and nurtures a deep pipeline of colleagues, ready and capable to meet today’s challenges and grow with the business.</p>

						<p>Through a robust identification and engagement process, we prepare future DTCC leaders with accelerated development and learning programs to enhance their skills and knowledge. In 2020, we executed all learning and development offerings as planned despite the pandemic, transforming in-person instruction to a virtual setting while maintaining the highest level of quality. We also reimagined the content itself by incorporating podcasts and short-form articles into our curriculum to fit different learning styles. </p>
					</div>

				</div>

				<div class="body-quote body-quote--2">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							Diversity, inclusion and representation power the best organizations and I am proud to be part of a company that is making such positive progress in recruiting diverse talent. Not only is diverse recruitment the right thing to do, it is the smart thing to do. Different backgrounds and perspectives lead to a variety of ideas, knowledge and ways of doing things. We are building a reputation for valuing differences and working to attract talented employees that feel they will be appreciated and able to utilize the skills they bring to the&nbsp;table.
							<div class="body-quote__credit">— <strong>Raina Rene</strong>,  Senior Associate and Co-Chair, BOLD Employee Resource Group  </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<div class="body-columns--small">
						<h2>Programming That <span>Drives Change</span></h2>
						<p>We also introduced exciting new initiatives in 2020, including several first-ever programs, including Catalyst’s Men Advocating for Real Change (MARC) workshop for our Officers, the Advancing Women Leaders program for Directors and the Re-Emerge Return to Work program for recruiting high-caliber talent seeking employment following a career break. We’ve learned over the years that development, mentorship and sponsorship, among other things, are all essential ingredients to building the diverse workforce we will need to succeed in the future. We are proud of the progress we have made in 2020, and we look forward to continued success in the years ahead.</p>
					</div>

				</div>

				<div class="leaders">
					<div class="csr__body">
						<div class="leaders__heading">
							<h2>Developing GLOBAL <br> LEADERS</h2>

							<div>
								<p>We focus on comprehensive programs aimed at all levels of employees from our two-year EDGE program, which ensures early career individuals have a comprehensive understanding of the company, the technical and functional skills required and leadership competencies to help them develop, to our Advancing Women Leaders and Emerging Women Leaders programs, which focus on helping women to build their leadership capabilities, as well as gain the senior leadership exposure to grow and advance their careers at DTCC.  </p>
							</div>
						</div>

						<boxes>
							<template v-slot:box1>
								<h3>Accelerating leaders</h3>
								<p>5-month, individualized program for high potential Directors and Executive Directors focused on enhancing key capabilities critical to future leadership success</p>
								<img src="~@/assets/csr/talentBox1.svg" width="300">
								<p><strong>Completed the program in 2020</strong></p>
							</template>

							<template v-slot:box2>
								<h3>Advancing women leaders</h3>
								<p>18-month individualized program to advance leadership development of women through coaching, mentorship and skill building</p>
								<img src="~@/assets/csr/talentBox2.svg" width="253">
								<p><strong>Completed the program in 2020</strong></p>
							</template>

							<template v-slot:box3>
								<h3>Role transitions</h3>
								<p>Support newly appointed people managers, Directors and Officers with curated curriculums that acclimate them to the roles and help them reach full productivity</p>
								<img src="~@/assets/csr/talentBox3.svg" width="400">
								<p><strong>Completed the program in 2020</strong></p>
							</template>

							<template v-slot:box4>
								<h3>Emerging women leaders</h3>
								<p>4-month program to accelerate emergent women leaders through management-focused skills development and capability-building stretch assignments</p>
								<img src="~@/assets/csr/talentBox4.svg" width="270">
								<p><strong>Completed the program in 2020</strong></p>
							</template>
						</boxes>
					</div>
				</div>

			</template>

			<template v-slot:footer>
				<article-next class="csr__next" :image="'csr/bgERG@2x.jpg'" :link="{name: 'csr', params: {
					article: 'erg'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Diversity & Inclusion</template>
					<template v-slot:title>Evolving our Employee Resource Groups</template>
				</article-next>
			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Talent',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {

	&__hero {
		background-image: url('~@/assets/csr/bgTalent@2x.jpg');
	}

	.body-quote {

		&--1 {
			background-image: url('~@/assets/csr/talentQuote1@2x.jpg');
		}

		&--2 {
			background-image: url('~@/assets/csr/talentQuote2@2x.jpg');
		}

	}

	.leaders {
		background-image: url('~@/assets/csr/bgTalent2.jpg');
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;

		&__heading {
			//display: flex;
			margin-bottom: 20px;
			color: #fff;
			text-shadow: $textshadow-1;

			@include media-query-min-width('tablet') {
				display: flex;
			}

			h2 {
				margin-top: 0;
				color: #fff;
			}

			* {
				flex: 1;
			}

		}

		::v-deep .boxes__box {
			text-align: center;

			h3 {
				@include fontSize(32px);
				@include gutter('margin-bottom', .5);
				justify-content: center;
				text-transform: uppercase;
			}

			p {
				@include fontSize(18px);
			}

			img {
				@include gutter('margin-bottom', .75);
				max-width: 100%;
			}
		}
	}
}

</style>
